import React, {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import styles from './Navbar.module.css';
import logo from './abdiz-logo.svg';

function Navbar() {

    return (
        <>
            <nav>
                <header className={styles.mainHeader}>
                    <Link
                        to='/'
                        className={styles.logoHolder}
                    >
                        <img src={logo} alt=""/>
                    </Link>


                    <Link
                        className={styles.navLink}
                        to='/contact'>
                        <span>Контакты</span>
                    </Link>
                </header>
            </nav>
        </>
    );
}

export default Navbar;
