import React from 'react';
import './App.css';
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import Navbar from "../NavBar/Navbar";
import Home from "../Home/Home";
import Contacts from "../Contacts/Contacts";
import Specialist from "../Specialist/Specialist";
import Footer from "../Footer/Footer";

const App = () => {

    return (
        <>
            <Navbar/>
            <Routes>
                <Route path='/'  element={<Home/>}/>
                <Route path='/contact' element={<Contacts />}/>
                <Route path='/specialists/:id' element={<Specialist/>}/>
            </Routes>

            <Footer/>
        </>
    );

}

export default App;