import React from 'react';
import styles from './css/ServicesContainer.module.css';
import chevronRight from './icons/chevron-right.svg';
import PropTypes from "prop-types";

function ServicesContainer({servicePackages}) {

    // Check if myObject exists before accessing its properties
    if (!servicePackages) {
        return <div>Loading...</div>;
    }

    return (
        <section className={styles.ServicesSection}>
            <h2>Услуги</h2>

            <div className={styles.ServicesContainer}>

                {
                    servicePackages.map((servicePackage) => {
                        return (
                            <div key={servicePackage.id} className={styles.serviceBox}>
                                <h3>{servicePackage.name}</h3>

                                <ul>
                                    {
                                        servicePackage.services.map((service) => {
                                            return (
                                                <li key={service.id}>
                                                    <img src={chevronRight}/>
                                                    {service.name}
                                                </li>
                                            );
                                        })
                                    }
                                </ul>

                                <div className={styles.priceContainer}>
                                    <span className={styles.price}>
                                        {servicePackage.price.formattedPrice}
                                    </span>
                                    <span style={{marginLeft: '6px'}}>
                                        за 1 кв.м
                                    </span>
                                </div>

                            </div>
                        );
                    })
                }
            </div>
        </section>
    );
}

// ServicesContainer.propTypes = {
//     servicePackages: [{
//         id: PropTypes.number.isRequired,
//         name: PropTypes.string.isRequired,
//         services: [
//             {
//                 id: PropTypes.number.isRequired,
//                 name: PropTypes.string.isRequired
//             }
//         ],
//         price: {
//             formattedPrice: PropTypes.string.isRequired
//         }
//     }
//     ]
// }
export default ServicesContainer;