import React, {useEffect, useState} from 'react';
import styles from './css/Specialist.module.css';
import {useParams} from 'react-router';
import arrowLeft from './icons/arrow-left.svg';
import eyeIcon from "../Card/eye.svg";
import sampleAva from './icons/sample-ava.png';
import {Link} from "react-router-dom";
import Contacts from "./Contacts";
import BioContainer from "./BioContainer";
import ServicesContainer from "./ServicesContainer";
import Portfolio from "./Portfolio";

function Specialist() {

    const {id} = useParams();
    const [specialistDetails, setSpecialistDetails] = useState({});
    const baseUrl = process.env.REACT_APP_BASE_URL;
    const imagesUrl = process.env.REACT_APP_IMAGES_URL;

    let url = `${baseUrl}/api/public/v1/specialists/${id}/details`;
    useEffect(() => {
        const fetchData = async () => {
            const response = await fetch(url);
            response.json().then(jsonData => {
                console.log("Json is read", jsonData);
                setSpecialistDetails(jsonData);
            });
        };
        fetchData();
    }, []);

    // scroll to top on page load
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []); // Empty dependency array ensures this effect only runs once

    return (
        <div className={styles.mainContainer}>
            <div className={styles.bannerSection}>

                {/*Banner header start*/}
                <div className={styles.bannerHeader}>
                    <Link to={'/'}>
                        <div className={styles.BackButton}>
                            <img src={arrowLeft} alt="Назад"/>
                            Назад
                        </div>
                    </Link>


                    <div className={styles.ViewsCount}>
                        <img src={eyeIcon} alt="Количество просмотров"/>
                        <span>{specialistDetails.viewsCount}</span>
                    </div>
                </div>
                {/*Banner header end*/}

                <div className={styles.InfoSection}>
                    <BioContainer specialist={specialistDetails.specialist}/>
                    <Contacts specialist={specialistDetails.specialist}/>
                    <div className={styles.AvatarContainer}>
                        {
                            specialistDetails.avatar &&
                            <img
                                src={`${imagesUrl}${specialistDetails.avatar.url}`}/>
                        }
                    </div>
                </div>

                <ServicesContainer servicePackages={specialistDetails.packages}/>
                <Portfolio projects={specialistDetails.livingSpaceProjects}/>
            </div>
        </div>
    );
}

export default Specialist;