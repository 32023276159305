import React, {useEffect, useState} from 'react';
import Cards from '../Card/Cards';
import HeroSection from "../HeroSection/HeroSection";


function Home() {
    const baseUrl = process.env.REACT_APP_BASE_URL;
    const URL = `${baseUrl}/api/public/v1/specialists/cards`;

    const [businesses, setBusinesses] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            const response = await fetch(URL);
            response.json().then(jsonData => {
                // console.log(jsonData);
                // console.log(jsonData.specialistCards);
                console.log("Json is read");
                setBusinesses(jsonData.specialistCards);
            });
        };
        fetchData();
    }, []);

    return (
        <div style={{backgroundColor: '#F7F7F7'}}>
            <HeroSection/>
            <Cards businesses={businesses}/>
        </div>
    );
}

export default Home;
